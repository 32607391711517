<template>
  <div id="depositFunds">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('menu.depositFund') }}</h2>
          <p></p>
        </div>
        <component :is="currentComponent"></component>
        <ul class="disclaimer" v-html="$t('deposit.default.disclaimer', { platform: $config.info.fullName })"></ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentComponent() {
      return () => this.$config.pageLoader(this.regulator, 'Deposit');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/depositFunds.scss';
</style>
